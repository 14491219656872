@value tablet from "../../../ui-kit/breakpoints.module.css";

.container,
.containerReverse {
  display: flex;
  align-items: center;
  gap: 80px;
}

.containerReverse {
  flex-direction: row-reverse;
}

.listContainer {
  flex: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: tablet) {
  .container,
  .containerReverse {
    flex-direction: column;
    gap: 24px;
  }
}
