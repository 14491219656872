@value desktop, tablet from "../../../ui-kit/breakpoints.module.css";

.buttonWrapper {
  margin-top: auto;
  padding-top: 16px;
}

.button {
  min-width: 0;
  justify-content: flex-start;
  width: 100%;
}

.cardsContainerSingle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  max-width: 700px;
  margin: auto;
}

.cardsContainerMultiple {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.cardContainer {
  grid-row: span 2;
  display: flex;
  flex-direction: column;
}

.textContainer {
  flex: 1;
  border: 1px solid #f2f2f7;
  border-radius: 0 0 26px 26px;
  padding: 16px;
}
@media screen and (max-width: desktop) {
  .cardsContainerMultiple {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: tablet) {
  .cardsContainerSingle {
    grid-template-columns: 1fr;
  }

  .cardsContainerMultiple {
    grid-template-columns: 1fr;
  }
}
