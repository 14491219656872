@value tablet, desktop from "../../../../ui-kit/breakpoints.module.css";
@value grey100, grey500 from "../../../../ui-kit/colors.module.css";

.packages {
  display: grid;
  grid-column-gap: 8px;
  max-width: 100%;
  overflow: hidden;
}

.packages button:active {
  opacity: 0.8;
}

.legend {
  display: contents;
}

.legendHeading {
  grid-column: 1;
  grid-row: 3;
  align-self: center;
  font-size: 22px;
  line-height: 28px;
}

.legendItem {
  grid-column: 1;
  padding: 0 8px;
  border-bottom: 1px solid grey100;
}

.legendItemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 0;
  cursor: pointer;
  position: relative;
  gap: 4px;
}

.legendItemDescription {
  max-height: 0;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.legendItem.descriptionOpened > .legendItemDescription {
  max-height: 400px;
  margin-bottom: 16px;
}

.packagesItems {
  display: contents;
}

.package {
  display: contents;
}

.packageBackground {
  grid-row: 2 / span 100; /* bigger value than needed but it is ok*/
  width: 100%;
  background: #ffffff;
  border: 1px solid grey100;
  border-radius: 0 0 24px 24px;
}

.packageCard {
  grid-row: 2;
  display: flex;
  justify-content: flex-end;
  position: relative;
  border-radius: 24px 24px 0 0;
}

.packageTitle {
  grid-row: 3;
  font-weight: 700;
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
}

.packageProp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 8px;
  padding: 8px 0;
  color: grey500;
  border-bottom: 1px solid grey100;
}

.packageProp:last-child {
  border: none;
}

.packageProp img {
  height: 32px;
  pointer-events: none;
  user-select: none;
}

.packageProp p {
  white-space: pre-wrap;
  margin: auto 0;
  font-size: 16px;
  line-height: 19px;
}

.factSheet {
  display: flex;
  align-items: center;
  padding: 4px 0;
}
