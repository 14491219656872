.checkpoints {
  color: #535457;
}

.checkpoints a {
  color: #14267b;
  font-weight: 600;
  text-decoration: none;
}

.checkPoint {
  margin-right: 8px;
  margin-top: 3px;
}
