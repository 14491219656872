@value tablet from "../../../ui-kit/breakpoints.module.css";

.benefits {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 48px 16px;
}

.sideBySide {
  grid-gap: 48px 32px;
}

.accordion {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  row-gap: 0;
}

.accordionItemWrapper {
  border: none;
  margin-bottom: 16px;
}
.accordionItem {
  display: flex;
  flex-direction: column;
  border: 1px solid lightgrey !important;
  border-radius: 16px;
  padding: 0 16px;
}

.accordionTitle {
  height: 70px;
  padding: 0;
}
.accordionText {
  padding: 0 10px 16px 10px;
}

@media screen and (max-width: tablet) {
  .benefits {
    grid-template-columns: repeat(2, auto);
  }

  .sideBySide {
    grid-template-columns: auto;
  }

  .accordion {
    grid-template-columns: repeat(1, 1fr);
  }
}
