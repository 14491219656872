.iconButton {
  padding: 6px;
  border-radius: 8px;
  background-color: #f2f2f7;
  text-align: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
