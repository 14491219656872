@value tablet from "../../../ui-kit/breakpoints.module.css";

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 24px;
  align-items: stretch;
}

.item {
  display: flex;
  flex: 1;
}

/*Rich text*/
.innerItemContainer > div {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.innerItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.innerItemContainer div {
}

.smallCard > .innerItemContainer {
  border: 1px solid #f2f2f7;
  border-radius: 24px;
  padding: 36px;
}

.smallCard div {
  font-size: 16px;
  line-height: 135%;
  color: #535457;
}

.card > .innerItemContainer {
  border: 1px solid #f2f2f7;
  border-radius: 24px;
  padding: 8px;
}

.bigPadding {
  padding: 52px;
}

.smallPadding {
  padding: 16px;
}

.framePadding {
  padding: 48px 16px 0 32px;
}

.framePadding {
  border-radius: 0 !important;
}

.verticalTop div {
  justify-content: flex-start;
}

.verticalCenter div {
  justify-content: center;
}

.verticalBottom div {
  justify-content: flex-end;
}

.verticalSpaceBetween div {
  justify-content: space-between;
}

.video {
  padding: 52px 52px 0;
  margin-bottom: -8px; /*video is deeply nested in richtext and it is the easiest way to align padding */
  width: 100%;
}

.video video {
  height: 100% !important;
  border-radius: 26px;
}

.horizontalLeft div {
}

.horizontalCenter div,
.horizontalCenter span {
  align-items: center;
  text-align: center !important;
}

.horizontalRight div {
  align-items: flex-end;
}

.imageContain img {
  object-fit: contain;
}

@media screen and (max-width: tablet) {
  .container {
    flex-direction: column;
  }

  .default {
    padding: 0px;
  }
}

@media screen and (min-width: tablet) {
  .smallCard:first-of-type {
    padding: 0 16px 0 48px;
  }

  .smallCard:last-of-type {
    padding: 0 48px 0 16px;
  }
}

.iphoneFrame {
  position: relative;
  top: 0px;
  width: 100%;
  height: 450px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  right: 0;
}

.iphoneFrame img:not(.playBtn) {
  width: 100%;
  z-index: 300;
  position: absolute;
}

.iphoneFrame .playBtn {
  width: 150px;
  z-index: 400;
  position: absolute;
  left: 25%;
  top: 40%;
}

.iphoneFrame div:not(.iphoneFrameInner) {
  position: absolute;
  width: 100% !important;

  top: 7px;
  height: 573px !important;
}

.iphoneFrameInner {
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  width: 295px;
  height: 450px !important;
  overflow: hidden;
  top: 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
}

.iphoneFrame video {
  position: absolute;
}

@media (min-width: tablet) {
  .iphoneFrame {
    margin: auto;
    left: 0;
    right: 0;
    position: relative;
    top: 0;
    width: 403px;
    height: 630px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
  }

  .iphoneFrame img:not(.playBtn) {
    width: 100%;
    z-index: 300;
    position: absolute;
  }

  .iphoneFrame .playBtn {
    width: 150px;
    z-index: 400;
    position: absolute;
    left: 30%;
    top: 40%;
  }

  .iphoneFrame div:not(.iphoneFrameInner) {
    position: absolute;
    width: 100% !important;

    top: 19px;
    height: 820px !important;
  }

  .iphoneFrameInner {
    position: absolute;
    width: 403px;
    height: 630px !important;
    overflow: hidden;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
  }

  .iphoneFrame video {
    position: absolute;
  }

  .content {
    padding: 34px 16px;
  }

  .videoContent {
    padding: 0;
  }

  .textContent {
    padding-top: 50px;
  }

  .framePadding {
    padding: 36px 36px 0;
  }
}

.imageContain img {
  object-fit: contain !important;
}

.withBorder {
  border: 1px solid #f2f2f7;
  border-radius: 24px;
}

.receipt {
  padding: 0 72px;
}

.receipt * {
  border-radius: 0 !important;
}
