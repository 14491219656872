.media {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 26px;
}

.square {
  aspect-ratio: 1 / 1;
}

.round {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
}

.header {
  aspect-ratio: 2 / 1;
  border-radius: 26px 26px 0 0;
}
