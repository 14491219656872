@value white, blue300, grey300, grey400, grey600 from "../../../ui-kit/colors.module.css";
@value mobile, desktop, tablet from "../../../ui-kit/breakpoints.module.css";

.main {
  margin: 0 auto;
  padding: 0 90px;
}

.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 73px;
  line-height: 73px;
  color: #14267b;
}

.heading2 {
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 73px;
  text-align: center;
  color: #14267b;
}

.subHeading {
  margin: auto;
  padding: 27px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  text-align: center;
  color: #000000;
}

.selectedCategories {
  display: flex;
  flex-wrap: wrap;
  height: 48px;
}

.selectedCategory {
  display: flex;
  margin-right: 16px;
  padding: 0 21px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.selectedCategory:last-child {
  margin-right: 0;
}

.selectedCategory * {
  margin: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  text-align: center;
  color: #000000;
}

.content > div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 100%;
  padding: 0;
}

.content > .bottomButton {
  display: flex;
}

.content > .bottomButton > * {
  width: 182px;
  margin: 0 auto;
}

.noArticles {
  margin: auto;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
}

@media screen and (max-width: desktop) {
  .content > div {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: tablet) {
  .main {
    padding: 0 45px;
  }

  .selectedCategories {
    margin-bottom: 8px;
  }

  .selectedCategory {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .content > div {
    grid-template-columns: 1fr;
  }

  .heading {
    font-size: 42px;
    line-height: 52px;
  }
  .heading2 {
    font-size: 32px;
    line-height: 52px;
  }
}

@media screen and (max-width: mobile) {
  .main {
    padding: 0;
  }
}

.reactPaginate {
  text-align: center;
}
.reactPaginate ul {
  display: inline-block;
  padding-top: 1px;
  margin-left: 20px;
  padding-left: 0;
  background: grey600;
  border-radius: 24px;
  padding-left: 21px;
  padding-right: 21px;
  line-height: 38px;
  padding-top: 2px;
}

.reactPaginate li {
  display: inline-block;
  border: 1px solid rgb(224, 224, 224);
  /* background: white; */
  color: grey400;
  cursor: pointer;
  margin-right: 3px;
  border-radius: 5px;
  border: 0px solid transparent;
}

.reactPaginate li a {
  padding: 2px 10px;
  display: inline-block;
  color: grey400;
  outline: none;
  font-family: var(--font-eina-semi-bold);
  font-size: 18px;
  width: 43px;
  height: 43px;
}

.selected {
  background: white !important;
  outline: none;
  border-radius: 100% !important;
  border: 1px solid grey300 !important;
}
.selected a {
  color: black !important;
}

@media (max-width: tablet) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
