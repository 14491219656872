@value mobile from "../../../../ui-kit/breakpoints.module.css";

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.title {
  margin: auto;
  display: flex;
  flex-direction: column;
}

.widget {
  margin-left: -8px;
}

@media (max-width: mobile) {
  .header {
    display: unset;
    margin: unset;
  }

  .header .title {
    margin: unset;
    display: unset;
    max-width: unset;
  }
}
