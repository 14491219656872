@value grey100, navy100, blue300 from '../colors.module.css';

.outer {
  border-radius: 25px;
  box-sizing: content-box;
  background: white;
  position: relative;
}

.container {
  background-color: navy100;
  border-radius: 25px;
  height: 50px;
  position: relative;
}

.track {
  position: relative;
  height: calc(100%);
}

.button {
  z-index: 1;
  background-color: white;
  border: 1px solid blue300;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  height: 100%;
  transition: all 250ms ease-in-out;
  will-change: transform;
  transform: translateX(0);
}

.options {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid grey100;
  border-radius: 25px;
}

.option {
  z-index: 1;
  flex: 1;
  width: 50%;
  height: 100%;
  text-align: center;
  transition: all 200ms ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content {
  padding-top: 16px;
  line-height: 115%;
}
