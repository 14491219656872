@value grey200 from '../../../../ui-kit/colors.module.css';

.wrapper {
  gap: 20px;
  height: 50;
}

.container {
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 8px;
}

.content {
  display: flex;
  flex-direction: column;
}

.divider {
  width: 2px;
  background: grey200;
  height: 24px;
}
