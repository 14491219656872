@value tablet from '../../../ui-kit/breakpoints.module.css';

.wrapper {
  display: flex;
  max-width: 1080px;
  width: 100%;
  margin: auto;
  position: relative;
}

.wrapper::before,
.wrapper::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 60px;
  z-index: 1;
}

.wrapper::after {
  background: linear-gradient(to left, rgb(255 255 255), transparent);
  right: 0;
}
.wrapper::before {
  background: linear-gradient(to right, rgb(255 255 255), transparent);
  left: 0;
}
.container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.container img {
  margin: 0 30px;
}

@media screen and (max-width: tablet) {
  .container img {
    margin: 0 25px;
  }
}
