@value grey500 from "../../../../ui-kit/colors.module.css";
@value tablet from "../../../../ui-kit/breakpoints.module.css";

.usefulInformation p {
  grid-row: 2;
  margin: 0;
  font-size: 17px;
  line-height: 150%;
  text-align: center;
}

.advice p {
  color: grey500;
}

.advices {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 50px;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 72px;
  justify-content: center;
}

@media screen and (max-width: tablet) {
  .advices {
    grid-template-columns: 1fr;
  }

  .advice {
    width: 100%;
    display: block;
    text-align: center;
  }
}
