@value grey100, navy300, blue300 from "../../../ui-kit/colors.module.css";

.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.item {
  padding: 8px 0;
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-content: left;
  align-items: center;
}

.title {
  overflow: hidden;
}

.text {
  grid-column-start: 2;
  grid-column-end: 3;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.open {
  max-height: 1000px;
}

.collapsible {
  cursor: pointer;
}

.separator:not(:last-of-type) {
  border-bottom: 1px solid grey100;
}

.listStyle {
  margin-right: 16px;
}

.listStyleCheck {
  margin-right: 12px;
  margin-left: -4px;
}

.listStyleNumber {
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 50%;
  text-align: center;
  background-color: grey100;
  color: blue300;
  margin-right: 16px;
  margin-right: 16px;
}
