@value tablet from '../../../ui-kit/breakpoints.module.css';

.reviewsSection {
  display: flex;
  gap: 24px;
  margin: 20px auto;
  align-items: center;
}

@media screen and (max-width: tablet) {
  .reviewsSection {
    justify-content: center;
  }
}
