@value tablet from "../../ui-kit/breakpoints.module.css";

.container > h3 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
}

.container h4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
}

.container p {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
}

.container > div {
  flex: 1;
}

.container img {
  border-radius: 16px;
}

@media screen and (max-width: tablet) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    gap: 8px;
  }

  .container > div {
    width: 100%;
  }

  .container h3 {
    font-size: 20px;
    line-height: 120%;
  }

  .container p {
    font-size: 16px;
    line-height: 135%;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .container.mobileReverse {
    flex-direction: column;
  }

  .mobileCard {
    border: 1px solid #f2f2f7;
    border-radius: 24px;
    padding: 8px;
  }
}
