.ul {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ol {
  margin-bottom: 8px;
  margin-top: 8px;
}

.quote {
  border-left-color: whitesmoke;
  border-left-width: 8px;
  justify-content: center;
  padding-top: 8px;
  padding-left: 8px;
}

.hr {
  display: block;
  margin-left: 16px;
  margin-right: 16px;
  border-bottom-width: 1px;
  border-bottom-color: grey;
}

.underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.italic {
  font-style: italic;
}
