@value tablet from "../../../ui-kit/breakpoints.module.css";

.card {
  border: 1px solid #f2f2f7;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.card > div {
  flex: 1;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: tablet) {
  .card {
    flex-direction: column-reverse;
  }
  .imgContainer img {
    width: unset;
  }
  .imgContainer {
    height: 175px;
  }
  .card > div {
    flex: unset;
  }
}
