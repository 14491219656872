@value blue300 from "../../../ui-kit/colors.module.css";

.textContainer {
  display: flex;
  flex-direction: column;
}

.markdown a {
  color: blue300;
  font-family: var(--font-eina-semi-bold);
  font-weight: 400;
}
