@value grey100 from "../../../../ui-kit/colors.module.css";

.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}

.row {
  display: contents;
}

.rowLegend {
  padding: 16px 8px 0 8px;
  align-items: center;
}

.rowLegendTitle {
  padding-bottom: 16px;
}

.rowLegendDescription {
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  border-bottom: 1px solid grey100;
}

.rowIcon {
  padding: 16px 0;
  justify-content: center;
  align-items: center;
}

.rowDescription {
  padding: 16px 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rowIpid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
}
