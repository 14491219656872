@value mobile from "../../../../ui-kit/breakpoints.module.css";

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(128, 128, 128, 0.3);
  width: 100%;
  height: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

.displayOverlay {
  visibility: visible;
  opacity: 1;
}

.slip {
  position: fixed;
  overflow: auto;
  z-index: 100;
}

.box {
  background-color: white;
  max-width: 500px;
}

.full {
  background-color: white;
  max-width: 100%;
}

.header {
  padding-top: 24px;
}

.content {
  padding: 24px 0;
}

@media (max-width: mobile) {
  .slip {
    bottom: 0;
    left: 0;
    max-height: 0;
    width: 100%;
    transition: max-height 300ms ease-in-out;
  }

  .displaySlip {
    max-height: 90%;
  }

  .box {
    border-radius: 16px 16px 0 0;
  }

  .full {
    height: 90%;
  }
}

@media (min-width: mobile) {
  .slip {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -46%);
    width: max-content;
    max-height: 90%;
    border-radius: 16px;
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .displaySlip {
    opacity: 1;
  }
}
