@value mobile as mobileBreakpoint, tablet as tabletBreakpoint from "../breakpoints.module.css";

.base {
  display: none;
}

@media (max-width: mobileBreakpoint) {
  .base.mobile {
    display: contents;
  }
}

@media (min-width: mobileBreakpoint) and (max-width: tabletBreakpoint) {
  .base.tablet {
    display: contents;
  }
}

@media (min-width: tabletBreakpoint) {
  .base.desktop {
    display: contents;
  }
}
