.bubbles {
    background: #E2EBFF;
    border-radius: 0px 16px 16px 16px;
    margin: 8px 0 0;
    padding: 16px 16px 16px 32px;
}

.unstyledList {
    list-style: none;
    padding-left: 0;
}

.starRow {
    display: flex;
    align-items: center;
    padding-bottom: 12px;
}

.list {
    padding-left: 20px;
}

.checkmarkList li:before {
    content: "✓";
    padding-right: 6px;
}