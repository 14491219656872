@value tablet from "../../../../ui-kit/breakpoints.module.css";

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.card {
  padding: 8px;
  border: 1px solid #f2f2f7;
  border-radius: 24px;
  flex: 1;
}

.card img {
  border-radius: 16px;
}

.cardText {
  padding: 16px;
}

.logo {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: tablet) {
  .container {
    flex-direction: column;
  }
}
