@value mobile, tablet, desktop from "../../../ui-kit/breakpoints.module.css";

.container {
  background: linear-gradient(
    to bottom,
    rgb(190, 205, 236) 0%,
    rgb(229, 247, 254) 99%,
    rgb(230, 248, 254) 100%
  );
  padding: 0 16px;
  overflow: hidden;
}

.content {
  margin: auto;
  max-height: 1200px;
  max-width: 1400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mobileImage {
}

.desktopImage {
  display: none;
}

.titles {
  max-width: 300px;
  margin-bottom: 8px;
}

.title {
  color: #14267b;
}

.subTitle {
  color: rgba(20, 38, 123, 0.6);
}

.tagLine {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.usps {
  display: flex;
  flex-wrap: wrap;
  max-width: 400px;
}

.uspList {
  list-style: none;
  padding-left: 0;
}

.uspListItem {
  margin-bottom: 8px;
}

.textContent {
  max-width: 654px;
  z-index: 1;
}

.picture {
  flex: 1;
}

.trustpilot {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  animation: fadeIn 1.5s;
}

.button {
  margin-top: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: mobile) {
  .mobileImage {
    display: none;
  }
  .desktopImage {
    display: block;
  }
  .titles {
    margin-bottom: 24px;
    padding-top: 40px;
  }
  .image {
    position: absolute;
    top: 0;
    right: 100px;
    width: 500px;
    object-fit: cover;
    z-index: 0;
  }
  .button {
    width: min(258px, 100%);
  }
  .trustpilot {
    margin-left: -20px;
    justify-content: flex-start;
  }
  .speechMobile {
    display: none;
  }
  .uspListItem {
    margin-bottom: 8px;
  }
}

@media (min-width: mobile) and (max-width: tablet) {
  .content {
    min-width: 768px;
  }
  .textContent {
    max-width: 500px;
  }
  .titles {
    max-width: none;
    margin-bottom: 24px;
  }
  .image {
    margin-bottom: 20px;
    width: 600px;
    right: 0px;
  }
  .topRankedImage {
    top: 110px;
    right: 70px;
    transform: scale(1.1) rotate(11.5deg);
  }
  .speechTablet {
    display: block;
  }
}

@media (min-width: tablet) {
  .titles {
    max-width: none;
  }
  .speechTablet {
    display: none;
  }
  .speechDesktop {
    display: block;
  }
}

@media (min-width: tablet) and (max-width: desktop) {
  .titles {
    padding-top: 68px;
  }
  .speechDesktop {
    top: -30px;
    right: 330px;
  }
  .image {
    width: 900px;
  }
  .content {
    min-width: 1140px;
  }
  .textContent {
    margin: 0 0 0 24px;
  }
  .topRankedImage {
    top: 170px;
    right: 140px;
    transform: scale(1.3) rotate(11.5deg);
  }
}

@media (min-width: desktop) {
  .button {
    width: min(309px, 100%);
  }
  .image {
    width: 960px;
  }
  .press {
    margin-left: 20px;
    transform: scale(1.3);
    transform-origin: left;
  }

  .titles {
    padding-top: 80px;
  }

  .textContent {
    margin: 0 0 0 40px;
  }

  .topRankedImage {
    top: 150px;
    right: 170px;
    transform: scale(1.5) rotate(11.5deg);
  }
}
