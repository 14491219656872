.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.content {
  z-index: 1;
  width: 100%;
}

.background {
  position: absolute;
  width: 300%;
  height: calc(100% + 160px);
  margin-left: -100%;
  margin-right: -100%;
}
