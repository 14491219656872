@value mobile from "../../../../ui-kit/breakpoints.module.css";

.mediaArticle {
  margin-top: 32px;
}

.mediaArticle button {
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.mediaArticle button:active {
  opacity: 0.8;
}

.mediaItems {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 24px;
  margin-top: 32px;
}

@media screen and (max-width: mobile) {
  .mediaItems {
    grid-template-columns: 1fr;
    grid-row-gap: 49px;
  }
}
