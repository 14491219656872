@value tablet, desktop from "../../../../ui-kit/breakpoints.module.css";
@value grey100 from "../../../../ui-kit/colors.module.css";

.gridContainer {
  gap: 8px;
  display: grid;
  justify-items: center;
}

.swiper {
  margin: 0 -16px;
}
