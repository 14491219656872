@value tablet from "../../../ui-kit/breakpoints.module.css";

.container {
  display: flex;
  gap: 80px;
}

.textContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@media (max-width: tablet) {
  .container {
    flex-direction: column;
    gap: 48px;
  }
}
