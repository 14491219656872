@value tablet from "../../../../ui-kit/breakpoints.module.css";

.container {
  padding: 16px 16px 16px 40px;
  background: #14267b;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
}

.item {
  flex: 1;
}

.checkItem {
  background: #e2ebff;
  border-radius: 0px 16px 16px 16px;
  padding: 16px;
}

.checkItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.arrowRight,
.arrowDown {
  padding: 24px;
  align-items: center;
  justify-content: center;
}

.arrowRight {
  display: flex;
}

.arrowDown {
  display: none;
}

.arrowRight img,
.arrowDown img {
  width: 22px;
  height: 22px;
}

@media screen and (max-width: tablet) {
  .container {
    flex-direction: column;
    padding: 16px;
  }
  .arrowRight {
    display: none;
  }
  .arrowDown {
    display: flex;
  }
  .mobileMargin {
    margin-top: 8px;
  }
}
