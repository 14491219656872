@value mobile, tablet, desktop from "../../ui-kit/breakpoints.module.css";
@value blue300 from "../../ui-kit/colors.module.css";

.container {
  display: flex;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    rgb(190, 205, 236) 0%,
    rgb(229, 247, 254) 99%,
    rgb(230, 248, 254) 100%
  );
  padding: 0 16px;
  overflow: hidden;
}

.content {
  max-height: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.titles {
  max-width: 350px;
  margin-bottom: 8px;
}

.title {
  color: blue300;
}

.subTitle {
  color: rgba(20, 38, 123, 0.6);
}

.tagLine {
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
}

.usps {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}

.uspList {
  padding-left: 16px;
  color: rgba(20, 38, 123, 0.5);
  list-style-position: outside;
}

.uspListItem {
  margin-bottom: 8px;
}

.textContent {
  max-width: 654px;
}

.picture {
  flex: 1;
}

.press {
  margin: auto;
  max-height: 1200px;
  max-width: 1400px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.pressHeader {
  margin-top: auto;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: blue300;
  padding-left: 32px;
  padding-bottom: 16px;
}

.trustpilot {
  display: flex;
  justify-content: center;
  margin: 40px 0;
  animation: fadeIn 1.5s;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: mobile) {
  .image {
    width: 250px;
  }
}

@media (min-width: mobile) {
  .image {
    width: 300px;
  }
}

@media (min-width: 600px) {
  .content {
    flex-direction: row-reverse;
    justify-content: space-evenly;
    padding-top: 80px;
  }

  .button {
    width: min(258px, 100%);
  }

  .trustpilot {
    margin-left: -20px;
    justify-content: flex-start;
  }

  .uspListItem {
    margin-bottom: 8px;
  }
}

@media (min-width: 600px) and (max-width: tablet) {
  .textContent {
    max-width: 500px;
  }

  .titles {
    max-width: none;
    margin-bottom: 24px;
  }
}

@media (min-width: tablet) {
  .titles {
    max-width: none;
  }
}

@media (min-width: tablet) and (max-width: desktop) {
  .image {
    width: 400px;
  }

  .textContent {
    margin: 0 0 0 24px;
  }
}

@media (min-width: desktop) {
  .button {
    width: min(309px, 100%);
  }

  .image {
    width: 500px;
  }

  .press {
    margin-left: 20px;
    transform: scale(1.3);
    transform-origin: left;
  }
}
