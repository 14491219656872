@value blue100, sun200 from "../../../ui-kit/colors.module.css";
@value mobile, tablet, desktop from "../../../ui-kit/breakpoints.module.css";

.container {
  position: relative;
}

.content {
  width: 100%;
  max-width: 1080px;
  padding: 0 24px;
  margin: auto;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 1;
  position: relative;
  height: 100%;
}

.mediaContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.media {
  height: 100%;
  transform: translateX(-50%);
  position: relative;
  left: 50%;
  width: auto;
}

.badgeText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.textContent {
  width: 40%;
}

.title {
  margin-bottom: 16px;
}

.button * {
  width: 100%;
}

.imageBadge {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  padding: 12px 16px;
  align-items: flex-end;
  gap: 12px;
  flex-shrink: 0;
  flex-direction: column;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: sun200;
  padding: 12px;
  position: absolute;
  top: 20px;
  right: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-width: 100px;
}

.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: sun200;
  padding: 12px;
  position: absolute;
  top: 20px;
  right: 10px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  max-width: 100px;
}

.container {
  height: 90vh;
  display: flex;
  align-items: center;
}

.disclaimer {
  margin-top: 16px;
}

@media screen and (max-width: tablet) {
  .container {
    height: 100vh;
    align-items: flex-start;
  }

  .content {
    padding: 24px;
    max-width: 450px;
    /* height: calc(90vh - 80px); */
    align-items: flex-start;
    margin: 0 auto;
  }

  .mediaContainer {
    position: static;
  }

  .media {
    min-width: 100%;
    width: 100%;
    height: auto;
    position: absolute;
    bottom: 0;
    top: auto;
    left: auto;
    transform: none;
  }

  .textContent {
    width: 100%;
  }

  .button {
    margin: 0 auto;
  }

  .badgeText * {
    line-height: 1.2;
  }

  .badgeText {
    align-items: flex-start;
  }

  .avatars {
    height: 25px;
    width: auto;
  }

  .imageBadge {
    bottom: 20px;
    left: 20px;
    top: auto;
    right: auto;
    align-items: flex-start;
  }

  .title {
    text-align: center;
  }

  .description {
    text-align: center;
    display: inline-block;
  }
  .disclaimer {
    text-align: center;
  }
}
