@value mobile as mobileBreakpoint, desktop as desktopBreakpoint from "./breakpoints.module.css";

.mobile {
  display: none;
}
.tablet {
  display: none;
}
.desktop {
  display: none;
}
@media (max-width: mobileBreakpoint) {
  .mobile {
    display: block;
  }
}

@media (min-width: mobileBreakpoint) and (max-width: desktopBreakpoint) {
  .tablet {
    display: block;
  }
}

@media (min-width: desktopBreakpoint) {
  .desktop {
    display: block;
  }
}
