@value tablet from "../ui-kit/breakpoints.module.css";
@value grey100, green300 from "../ui-kit/colors.module.css";

.container {
  padding: 12px;
  gap: 12px;
  border-start-start-radius: 24px;
  border-start-end-radius: 24px;
}

.image {
  align-self: center;
}

.text {
  height: 100%;
  flex: 1;
}

.rounded {
  border-radius: 24px;
}

.popular {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

@media screen and (min-width: tablet) {
  .container {
    height: 98px;
  }
}
