@value mobile, tablet, desktop from "../../../../ui-kit/breakpoints.module.css";

.mediaItem {
  padding: 8px;
  width: 100%;
  border: 1px solid #f2f2f7;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainImage {
  position: relative;
  height: 251px;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
  overflow: hidden;
}

.mediaItem h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #535457;
  padding: 16px 16px;
}

.mediaItem span {
  font-size: 17px;
  line-height: 24px;
  color: #3c3c4399;
}

.mediaItem button {
  width: 186px;
  height: 48px;
  font-size: 16px;
  line-height: 150%;
  color: #14267b;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #e2ebff;
  border-radius: 100px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.date {
  font-size: 16px;
  color: #7d7d84;
  line-height: 135%;
}

.icon {
  width: 25px;
  height: 25px;
}

@media screen and (max-width: tablet) and (min-width: mobile) {
  .mediaItem button {
    width: 100%;
  }

  .row {
    margin-top: 8px;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  .mediaItem {
    height: auto;
  }
}

@media screen and (max-width: mobile) {
  .mediaItem button {
    width: 100%;
  }

  .row {
    margin-top: 8px;
    gap: 16px;
    flex-direction: column;
    align-items: flex-start;
  }
  .mediaItem {
    height: auto;
  }
}
