.cardContainer {
  grid-row: span 2;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.textContainer {
  flex: 1;
  border: 1px solid #f2f2f7;
  border-radius: 0 0 26px 26px;
  padding: 16px;
  background-color: white;
}
