@value tablet from "../../../ui-kit/breakpoints.module.css";

.container {
  width: max-content;
}

.button {
  min-width: 300px;
}

.supportButtons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: tablet) {
  .container {
    width: 100%;
  }

  .button {
    min-width: auto;
  }

  .supportButtons {
    flex-direction: column;
  }
}
