@value mobile, tablet from "../../../ui-kit/breakpoints.module.css";
@value sun100 from "../../../ui-kit/colors.module.css";

.container,
.containerStartPage {
  display: flex;
  align-items: center;
  gap: 125px;
}

.mediaContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 400px;
  display: flex;
}

.mediaContainerStartPage {
  flex: 1;
  height: 100%;
  width: 100%;
  max-width: 450px;
  display: flex;
  position: relative;
  border-radius: 50%;
}

.textContainer,
.textContainerStartPage {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.topBadge {
  position: absolute;
  top: -50px;
  right: -20px;
  background-color: sun100;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  z-index: 99;
}

.bottomBadge,
.bottomBadge:after,
.bottomBadge:before {
  position: absolute;
  width: 90px;
  height: 90px;
  border-radius: 20px;
  z-index: 99;
}

.bottomBadge {
  bottom: 0;
  left: 0;
}

.bottomBadge:after {
  content: "";
  top: 0;
  left: 0;
  transform: rotate(20deg);
  background: sun100;
}

.bottomBadge:before {
  content: "";
  top: 0;
  left: 0;
  transform: rotate(155deg);
  background: sun100;
}

.badgeContent {
  height: 100%;
  position: relative;
  padding: 0 10px;
  z-index: 100;
}

.list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style-type: none;
}

.listItem {
  display: flex;
}

.buttonContainer {
  min-width: 300px;
  width: fit-content;
}

@media (max-width: tablet) {
  .container {
    flex-direction: column;
    gap: 40px;
    margin-top: -40px;
  }

  .containerStartPage {
    flex-direction: column;
    gap: 60px;
    margin-top: -40px;
  }

  .textContainer {
    text-align: center;
  }

  .mediaContainer,
  .mediaContainerStartPage {
    max-width: 100%;
  }

  .topBadge {
    right: unset;
    left: -10px;
    top: -30px;
    width: 125px;
    height: 125px;
  }

  .bottomBadge {
    left: unset;
    right: 0;
  }

  .bottomBadge,
  .bottomBadge:after,
  .bottomBadge:before {
    width: 75px;
    height: 75px;
  }

  .shield {
    width: 60px;
    height: 60px;
  }

  .buttonContainer {
    width: 100%;
  }
}
