.container {
  display: flex;
  justify-content: flex-start !important;
  align-items: center;
}

@media (max-width: 365px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-top: 0;
  }
}
