@value navy100 from "../../../../ui-kit/colors.module.css";
@value tablet from "../../../../ui-kit/breakpoints.module.css";

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 48px 32px;
  width: 100%;
}

.document {
  background-color: navy100;
  padding: 32px;
  border-radius: 24px;
}

@media screen and (max-width: tablet) {
  .container {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}
