@value grey100, green300 from "../ui-kit/colors.module.css";

.container {
  border: 1px solid grey100;
  border-radius: 0 0 24px 24px;
  padding: 16px;
  height: 100%;
}

.rounded {
  border-radius: 24px;
}
